import React from 'react';
import { ImSpinner8 } from 'react-icons/im'; 

const Loader = () => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-[#78007a] rounded-full w-24 h-24 flex items-center justify-center">
                <ImSpinner8 className="text-white w-12 h-12 animate-spin" />
            </div>
        </div>
    );
}

export default Loader;

// Define base API URL
const baseApi = 'https://api.thequickcafe.com/api/v1/'

// Define endpoints
export const searchApi = `${baseApi}vm/search`
export const inventoryApi = (vmid) => `${baseApi}invetory/byvmid/${vmid}`
export const configApi = `${baseApi}payment/config`
export const statusApi = (vmid) => `${baseApi}vm/status?vmid=${vmid}`
export const checkoutApi = `${baseApi}checkout/withPayInit`
export const orderApi = (oid) => `${baseApi}order/${oid}`
export const paymentApi = (pyid) => `${baseApi}payment/${pyid}`
export const webSocketUrl = `wss://api.thequickcafe.com/api/v1/ws/user`
export const vmApi = (vmid) => `${baseApi}vm/byid/${vmid}`

var os = [
    { name: 'WINDOWS', value: 'Windows Phone', version: 'OS' },
    { name: 'WINDOWS', value: 'Win', version: 'NT' },
    { name: 'IOS', value: 'iPhone', version: 'OS' },
    { name: 'IOS', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'ANDROID', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'UBUNTU', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
]

var browser = [
    { name: 'MOB_CHROME', value: 'Chrome', version: 'Chrome' },
    { name: 'MOB_FIREFOX', value: 'Firefox', version: 'Firefox' },
    { name: 'MOB_SAFARI', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
]

var header = [
    navigator.platform,
    navigator.userAgent,
    navigator.appVersion,
    navigator.vendor,
    window.opera
];

const matchItemForDeviceInfo = (string, data) => {
    var i = 0,
        j = 0,
        html = '',
        regex,
        regexv,
        match,
        matches,
        version;
    
    for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([\d._]+)', 'i');
            matches = string.match(regexv);
            version = '';
            if (matches) { if (matches[1]) { matches = matches[1]; } }
            if (matches) {
                matches = matches.split(/[._]+/);
                for (j = 0; j < matches.length; j += 1) {
                    if (j === 0) {
                        version += matches[j] + '.';
                    } else {
                        version += matches[j];
                    }
                }
            } else {
                version = '0';
            }
            return {
                name: data[i].name,
                version: parseFloat(version)
            };
        }
    }
    return { name: 'unknown', version: 0 };
}


export const  getDeviceInfo = ()=>{
    var agent = header.join(' ');
    var detectedos = matchItemForDeviceInfo(agent, os);
    var detectedbrowser = matchItemForDeviceInfo(agent, browser);
    var d = {
        os:detectedos,
        browser:detectedbrowser
    }
    // console.log(detectedos,detectedbrowser)
    
    return d; 
    
}
import React from 'react';

const ConfirmationPopup = ({ message, items, onConfirm, onCancel }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm text-center">
                <p className="mb-4 text-lg">{message}</p>
                {items && 
                <ul className="item-list text-left mb-4 space-y-2">
                    {items.map((item, index) => (
                        <li key={index} className="flex justify-between">
                            <span>{item.itemName}</span>
                            <span>₹{item.price.toFixed(2)} x {item.count}</span>
                        </li>
                    ))}
                </ul>}
                <div className="flex justify-around mt-4">
                    <button 
                        onClick={onConfirm} 
                        className="px-4 py-2 bg-[#78007a] text-white rounded-md hover:bg-[#b338b6]"
                    >
                        Confirm
                    </button>
                    <button 
                        onClick={onCancel} 
                        className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmationPopup;

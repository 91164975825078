import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'

import PopupCard from '../Components/PopupCard'
import ProductCard from '../Components/ProductCard'
import { inventoryApi, statusApi } from '../services/api'
import OrderNowButton from '../Components/OrderNowButton'
import { useParams, useNavigate } from 'react-router-dom'
import PopupInfoCard from '../Components/PopupInfoCard'
import Loader from '../Components/Loader'
import ConfirmationPopup from '../Components/ConfirmationPopup'


const Products = () => {
    // const machineID = useSelector((state) => state.app.machineID)
    const navigate = useNavigate()
    const { vmid } = useParams()
    console.log('Machine ID :', vmid)
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [infoCardPopup, setInfoCardPopup] = useState(false);
    const [errorHandle, setErrorHandle] = useState([]);
    const [cart, setCart] = useState([])
    const [loading, setLoading] = useState(false);
    // Flag to trigger quick order
    const [triggerQuickOrder, setTriggerQuickOrder] = useState(false)
    const [productToOrder, setProductToOrder] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const statusResponse = await axios.get(statusApi(vmid))
                if (statusResponse.data.Data.status === 'ONLINE') {
                    const inventoryResponse = await axios.get(
                        inventoryApi(vmid)
                    )
                    setProducts(inventoryResponse.data.Data)
                    console.log(
                        'Fetched inventory:',
                        inventoryResponse.data.Data
                    )
                } else {
                    navigate('/offlineMachine')
                }
            } catch (error) {
                console.error('Error fetching data:', error)
                // Handle errors here
                openInfoPopup(error) // pass errors to Pop-up card
            } finally {
                setLoading(false) // diable loading screen when fetched finally
            }
        }

        fetchData()
        // eslint-disable-next-line
    }, [])
    // Fetch inventory when machineID changes

    const openPopup = (productId) => {
        setSelectedProduct(productId)
    }

    const closePopup = () => {
        setSelectedProduct(null)
    }

    const openInfoPopup = (err) => {
        setInfoCardPopup(true)
        setErrorHandle(err)
        console.log(err);
    }

    const closeInfoPopup = () => {
        setInfoCardPopup(false)
        // Back to Home 
        navigate('/')
    }

    // Function to handle quick order by updating cart and triggering the quick order
    // Handle Quick Order confirmation
    const handleQuickOrder = (product) => {
        setProductToOrder(product);
        setShowConfirmation(true); // Show confirmation popup
    };

    const confirmOrder = () => {
        setCart((prevCart) => ({
            [productToOrder.id]: {
                ...productToOrder,
                quantity: 1,
            },
        }));
        setTriggerQuickOrder(true);
        setShowConfirmation(false); // Close confirmation popup
    };

    const cancelOrder = () => {
        setShowConfirmation(false); // Close confirmation popup without ordering
    };

    return (
        <div className="h-full flex flex-col items-center">
            {/* Quick Order Buttons */}
            <h2 className="text-xl font-bold text-left">Quick Orders Section</h2>
            <div className="flex gap-2 my-4">
                {products.map((product) => (
                    <button 
                        key={product.id} 
                        onClick={() => handleQuickOrder(product)} 
                        className="btn-primary p-2 border bg-[#78007a] text-white rounded-md hover:bg-[#b338b6] shadow-lg"
                    >
                        1 Quick {product.product_name}
                    </button>
                ))}
            </div>

            <div className="flex flex-col gap-3 overscroll-x-auto px-2 w-full">
                {products.map((product) => (
                    <ProductCard
                        key={product.id}
                        product={product}
                        openPopup={openPopup}
                        cart={cart}
                        setCart={setCart}
                    />
                ))}
            </div>

            {selectedProduct !== null && (
                <PopupCard
                    product={products.find(
                        (product) => product.id === selectedProduct
                    )}
                    closePopup={closePopup}
                />
            )}

            {infoCardPopup && (
                <PopupInfoCard title={errorHandle.response.data.Status || errorHandle.response.request.status} message={errorHandle.response.data.Error || errorHandle.response.data.message} closePopup={closeInfoPopup} />
            )}

            <OrderNowButton cart={cart} vmid={vmid} openInfoPopup={openInfoPopup}  triggerQuickOrder={triggerQuickOrder}
                onQuickOrderHandled={() => setTriggerQuickOrder(false)} />
            {loading && (<Loader />)}

            {/* Confirmation Popup */}
            {showConfirmation && (
                <ConfirmationPopup
                    message={`Are you sure you want to order 1 ${productToOrder.product_name}?`}
                    onConfirm={confirmOrder}
                    onCancel={cancelOrder}
                />
            )}
        </div>
    )
}

export default Products

const PopupInfoCard = ({ title,message , closePopup }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 p-4">
            <div className="flex flex-col items-center justify-center gap-2 rounded-xl bg-white p-2">
                
                <h2 className=" text-lg font-semibold">
                    Error : {title}
                </h2>
               
                <p className="text-lg font-normal">
                    {message}
                </p>
                <button
                    className="w-full rounded-xl bg-[#78007a] p-2 text-white"
                    onClick={closePopup}
                >
                    Close
                </button>
            </div>
        </div>
    )
}

export default PopupInfoCard

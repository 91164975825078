import React, { useEffect, useState } from 'react';
import { getOrdersFromCookies, addOrderToCookies } from '../../Utili/cookieUtils';
import { checkoutApi, statusApi, vmApi } from '../../services/api';
import axios from 'axios';
import * as DeviceInfo from '../../Utili/DeviceInfo'
import Loader from '../Loader';
import { setCurrentPage, setQueryParams, setRedirect } from '../../actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ConfirmationPopup from '../ConfirmationPopup'

const RecentlyOrdered = () => {
    const [orders, setOrders] = useState([]);
    const [vmNames, setVmNames] = useState({});
    const [loading, setLoading] = useState(false);
    const [responseMessage, setResponseMessage] = useState(null);
    const [erresponseMessage, setErResponseMessage] = useState(null);
    const [fadeOut, setFadeOut] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const deviceInfo = DeviceInfo.getDeviceInfo();
    const navigate = useNavigate()
    const dispatch = useDispatch();

    useEffect(() => {
        const ordersFromCookies = getOrdersFromCookies();
        setOrders(ordersFromCookies);
        fetchVmNames(ordersFromCookies)
    }, []);

    useEffect(() => {
        if (erresponseMessage) {
            // Start fading out after 5 seconds
            const fadeTimer = setTimeout(() => setFadeOut(true), 5000);

            // Remove the message after fading out
            const removeTimer = setTimeout(() => {
                setErResponseMessage(null);
                setFadeOut(false);
            }, 6000);

            // Clear timers on cleanup
            return () => {
                clearTimeout(fadeTimer);
                clearTimeout(removeTimer);
            };
        }
    }, [erresponseMessage]);

    const fetchVmNames = async (orders) => {
        const names = {};
        for (const order of orders) {
            const vmId = order.vendingMachineId;
            // Check local storage first
            const storedName = localStorage.getItem(`vmName_${vmId}`);
            if (storedName) {
                names[vmId] = storedName;
            } else {
                // Fetch from API if not in local storage
                try {
                    const response = await axios.get(vmApi(vmId));
                    if (response.data.Status === 200) {
                        const vmName = response.data.Data.name.String;
                        names[vmId] = vmName;
                        localStorage.setItem(`vmName_${vmId}`, vmName); // Store in local storage
                    }
                } catch (error) {
                    console.error('Error fetching VM name:', error);
                }
            }
        }
        setVmNames(names);
    };

    const shortenName = (name, maxLength = 15) => {
        return name.length > maxLength ? name.substring(0, maxLength) + '...' : name;
    };
    

    const getQuery = (url) => {
        const params = "";
        const urlParts = url.split('?');
        if (urlParts.length > 1) {
            const queryString = urlParts[1];
            return queryString
        }
        return params;
    };

    const handleReorder = (order, event) => {
        event.stopPropagation(); // Prevent card click event from firing
        setSelectedOrder(order);
        setShowConfirm(true); // Show the confirmation dialog
    };
    

    const processReorder = async (order, event) => {
        // event.stopPropagation(); // Prevent card click event from firing
        setLoading(true);

        const items = order.items.map((item) => ({
            invid: parseInt(item.invid),
            count: parseInt(item.count),
            itemName: item.itemName,
            price: parseInt(item.price),
            subtotal: parseInt(item.price * item.count),
        }));

        console.log(order);
        
        const requestData = order.requestData;
        
        console.log(requestData);
        

        try {
            const statusResponse = await axios.get(statusApi(parseInt(order.vendingMachineId)));
            console.log(statusResponse);
            
            if (statusResponse.data.Data.status !== 'ONLINE') {
                navigate('/offlineMachine'); // Redirect to offline page if machine is not online
                setLoading(false);
                return;
            }

            addOrderToCookies({
                id: Date.now(),
                items,
                total: order.total,
                requestData,
                timestamp: new Date().toISOString(),
                vendingMachineId: order.vendingMachineId,
            });

            const response = await axios.post(
                checkoutApi,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-DEVICEBROWSER': 'MOB_CHROME',
                        'X-DEVICEOS':  'ANDROID',
                    },
                }
            );

            setResponseMessage(response.data);
            console.log("Order placed successfully:", response.data);
            const redirectUrl = response.data.Data.PayRsponse.data.instrumentResponse.redirectInfo.url;
            console.log(redirectUrl);

            const queryURL = getQuery(response.data.Data.Redirect.url)
            console.log(`Query URL --> ${queryURL}`);

            addOrderToCookies({
                id: Date.now(),
                items,
                total: order.total,
                requestData,
                queryURL,
                timestamp: new Date().toISOString(),
                vendingMachineId: order.vendingMachineId,
            });

            dispatch(setRedirect(redirectUrl))
            dispatch(setQueryParams(queryURL))
            navigate(`/vendingmachine/${order.vendingMachineId}?${queryURL}`)
            window.open(redirectUrl)
            dispatch(setCurrentPage(3))
            console.log("Shifted to page 2");
            
        } catch (error) {
            console.error('Error reordering item:', error);
            setErResponseMessage(error.response?.data ||  'Order could not be processed.');
            // if(error.response.data.Status === 500)
            //     setErResponseMessage('Machine Unavailable: Failed to get VM Status')
        } finally {
            setLoading(false);
        }
    };

    const handleCardClick = (order) => {
        if (order.queryURL) {
            dispatch(setQueryParams(order.queryURL));
            dispatch(setCurrentPage(3));
            navigate(`/vendingmachine/${order.vendingMachineId}?${order.queryURL}`);
        }
    };

    return (
        <div className="recently-ordered-container p-4 rounded-lg mt-4">
            <h2 className="text-xl font-bold mb-4 text-left">Recently Ordered</h2>
            {erresponseMessage && (
                <div className={`my-4 p-4 bg-[#78007a] text-center text-white rounded-lg ${fadeOut ? 'fade-out' : ''}`}>
                    {erresponseMessage}
                </div>
            )}
            <ul className="order-list space-y-4">
                {orders.map((order, index) => (
                    <li 
                    key={index} 
                    className="order-item p-2 border border-[#78007a] rounded-lg flex flex-col space-y-1 cursor-pointer shadow-sm hover:shadow-md transition-shadow duration-200"
                    onClick={() => handleCardClick(order)}
                >
                    <div className="flex justify-between items-center">
                        <div className="font-bold text-lg text-[#78007a]">₹{order.total.toFixed(2)}</div>
                        <div className="text-xs text-gray-500">{new Date(order.timestamp).toLocaleString()}</div>
                    </div>
                    <div className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">{shortenName(vmNames[order.vendingMachineId] || 'Loading...')}</span>
                        <button
                            onClick={(event) => handleReorder(order, event)}
                            className="px-2 py-1 bg-[#78007a] text-white text-md rounded-md hover:bg-[#5e005d] transition-colors duration-150"
                        >
                            Order Again
                        </button>
                    </div>
                    <ul className="item-list pl-3 list-disc text-sm text-gray-700 space-y-1 mt-1">
                        {order.items.map((item, i) => (
                            <li key={i} className="flex justify-between">
                                <span>{item.itemName}</span>
                                <span>₹{item.price.toFixed(2)} x {item.count}</span>
                            </li>
                        ))}
                    </ul>
                </li>                
                ))}
            </ul>

            {loading && <Loader />}

            {showConfirm && (
                <ConfirmationPopup
                    items={selectedOrder.items}
                    message={`Are you sure you want to reorder items from ${shortenName(vmNames[selectedOrder.vendingMachineId] || 'Loading...')}?`}
                    onConfirm={() => {
                        setShowConfirm(false);
                        processReorder(selectedOrder); // Call processReorder directly
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                />
            )}

        </div>
    );
};

export default RecentlyOrdered;

import React, { useEffect, useState, useRef } from 'react';
import DisplayBox from '../Components/Dispense/DisplayBox';
import OrderDetails from '../Components/Dispense/OrderDetails';
import DispenseButton from '../Components/Dispense/DispenseButton';
import { orderApi, paymentApi, webSocketUrl } from '../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage } from '../actions';
import PopupInfoCard from '../Components/PopupInfoCard';
import { useNavigate } from 'react-router-dom';

const Dispense = () => {
    const details = useSelector((state) => state.app.orderDetails);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [buttonTxt, setButtonTxt] = useState();
    const [buttonStatus, setButtonStatus] = useState(false);
    const [queuePosition, setQueuePosition] = useState(0);
    const [tte, setTte] = useState(0);
    const [errorCardPopup, setErrorCardPopup] = useState(false);
    const [errorHandle, setErrorHandle] = useState([]);
    const [isOrderCompleted, setIsOrderCompleted] = useState(false);
    const [showReportPopup, setShowReportPopup] = useState(false); // State to control report popup
    const [mobileNumber, setMobileNumber] = useState(''); // Input state for mobile number
    const [description, setDescription] = useState(''); // Input Description about the Issue
    const [reportData, setReportData] = useState(''); // Holds JSON stringified order and payment data
    const intervalRef = useRef(null);
    const ws = useRef(null);

    useEffect(() => {
        const wsConnect = () => {
            if (!ws.current || ws.current.readyState === WebSocket.CLOSED) { // Check if WebSocket is closed or not created
                ws.current = new WebSocket(webSocketUrl);
                ws.current.onopen = () => {
                    console.log('WebSocket connection opened');
                    getOrderStatus(details.oid, details.vmid);
                };
                ws.current.onclose = () => {
                    console.log('WebSocket connection closed');
                    clearInterval(intervalRef.current);
                }
                ws.current.onmessage = (evt) => {
                    const obj = JSON.parse(evt.data);
                    console.log(obj);
                    
                    handleWebSocketMessage(obj);
                };
            }
        };

        const getOrderStatus = (orderid, vmid) => {
            if (ws.current && ws.current.readyState === WebSocket.OPEN) {
                const payload = `{"event":"get-order","payload":{"orderid":${orderid},"vmid":${vmid}}}`;
                ws.current.send(payload);
            } else {
                console.log('WebSocket connection is not open');
            }
        };

        const handleWebSocketMessage = (obj) => {
            switch (obj.Event) {
                case "get-order":
                    if (obj.Payload.vm_status === "ONLINE" || obj.Payload.vm_status === "") {
                        if (obj.Payload.quque_position === -5) {
                            completeOrder("Completed", obj);
                        } else if (obj.Payload.quque_position === -6) {
                            completeOrder("Cancelled", obj);
                        } else if (obj.Payload.quque_position === 1) {
                            handleExecutionStatus(obj);
                        } else {
                            setButtonTxt('Waiting for your Turn...');
                            setButtonStatus(true);
                            setQueuePosition(obj.Payload.queue_position);
                            setTte(obj.Payload.tte);
                        }
                    } else {
                        setButtonTxt('Machine is Offline');
                        setButtonStatus(true);
                        openErrorPopup({
                            Status: "Offline Machine",
                            Error: "The machine is currently offline",
                        });
                        clearInterval(intervalRef.current); // don't call again if machine is offline
                    }
                    break;
                case "start-despensing-order":
                    if (obj.error == null) {
                        setButtonTxt('Dispensing');
                        setButtonStatus(true);
                    }
                    break;
                default:
                    break;
            }
        };

        const completeOrder = (status, obj) => {
            console.log("Complteedt");
            
            setQueuePosition(0);
            setButtonTxt(status);
            setButtonStatus(true);
            setIsOrderCompleted(true);
            clearInterval(intervalRef.current);
            ws.current.close();
            dispatch(setCurrentPage(8));
        };

        const handleExecutionStatus = (obj) => {
            if (obj.Payload.exe_status === "executing") {
                setButtonTxt('Dispensing...');
                setButtonStatus(true);
            } else if (obj.Payload.exe_status === "waiting_user_start") {
                setButtonTxt('Start Dispensing');
                setButtonStatus(false);
            } else if (obj.Payload.exe_status === "user_did_press_start") {
                setButtonTxt('In Progress...');
                setButtonStatus(true);
            }
            setQueuePosition(obj.Payload.queue_position);
        };

        wsConnect();

        intervalRef.current = setInterval(() => {
            console.log("Fetching status");
            getOrderStatus(details.oid, details.vmid);
        }, 1000);

        return () => {
            clearInterval(intervalRef.current); // Clear interval on unmount
            if (ws.current) {
                ws.current.close();
            }
        };
    }, [details.oid, details.vmid, dispatch]);

    const startDispensing = (orderid) => {
        if (ws.current && ws.current.readyState === WebSocket.OPEN) {
            const payload = `{"event":"start-despensing-order","payload":{"orderid":${orderid}}}`;
            ws.current.send(payload);
        } else {
            console.log('WebSocket connection is not open');
        }
    };

    const openErrorPopup = (err) => {
        setErrorCardPopup(true);
        setErrorHandle(err);
        console.log(err);
    };

    const closeErrorPopup = () => {
        setErrorCardPopup(false);
    };

    const handleReorder = () => {
        dispatch(setCurrentPage(1));
        navigate(`/vendingmachine/${details.vmid}`);
    };

    const handleReportClick = async () => {
        try {
            setShowReportPopup(true);
            const orderResponse = await fetch(orderApi(details.oid));
            const orderData = await orderResponse.json();
            const paymentResponse = await fetch(paymentApi(details.pyid));
            const paymentData = await paymentResponse.json();

            setReportData(
                `Order Info: ${JSON.stringify(orderData)},\n` +
                `Payment Info: ${JSON.stringify(paymentData)}`
            );
            // setShowReportPopup(true);
        } catch (error) {
            console.error('Error fetching order or payment info:', error);
            setReportData('Failed to fetch order or payment details');
            setShowReportPopup(true);
        }
    };

    const handleReportSubmit = () => {
        const report = {
            reportData,
            mobileNumber,
            description,
        };
        console.log('Report submitted:', report);
        // Add any API or dispatch function to send the report to the server here
        setShowReportPopup(false);
        // clear details after submission of form.
        setMobileNumber('')
        setDescription('')

    };

    return (
        <div className="flex items-center justify-center bg-[#ffe6ff] mb-8">
            <div className="w-[90%] flex flex-col items-center max-w-md p-6 mt-4 bg-white rounded-xl shadow-md">
                <div className="flex flex-col items-center w-[90%]">
                    <div className="flex flex-row justify-between w-full">
                        <DisplayBox value={queuePosition} label="Your Queue Position" wd={24} />
                        <DisplayBox value={tte} label="Estimated waiting time" wd={24} />
                    </div>
                    <DisplayBox value="Thanks for Ordering" label="Item dispensed / Total Items" wd="full" />
                </div>
                <OrderDetails oid={details.oid} pyid={details.pyid} name="Quick Cafe" id={details.vmid} />
                <DispenseButton
                    buttonTxt={buttonTxt}
                    handleClick={() => startDispensing(details.oid)}
                    disabled={buttonStatus}
                />
                <div className='flex flex-row gap-4'>
                    {isOrderCompleted && (
                        <button onClick={handleReorder} className="mt-4 px-4 py-2 bg-[#78007a] text-white rounded-lg">
                            Order Again
                        </button>
                    )}
                    {/* Report Button */}
                    <button onClick={handleReportClick} className="mt-4 px-4 py-2 bg-[#78007a] text-white rounded-lg">
                        Report
                    </button>
                    
                </div>
                <div className="flex flex-col items-center w-[90%]">
                    <p>In case of an order-related issue, kindly Contact - <strong className="block">+91 - 8421586845</strong>.</p>
                </div>
            </div>

            {errorCardPopup && (
                <PopupInfoCard title={errorHandle.Status} message={errorHandle.Error} closePopup={closeErrorPopup} />
            )}

             {/* Report Popup */}
             {showReportPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-6 w-[90%] max-w-[400px]">
                        <h2 className="text-xl font-semibold mb-4">Report an Issue</h2>
                        <div className="mb-4">
                            <label className="block text-gray-700">Mobile Number</label>
                            <input
                                type="text"
                                value={mobileNumber}
                                placeholder='+91 - 8421586845'
                                onChange={(e) => setMobileNumber(e.target.value)}
                                className="mt-1 px-4 py-2 border rounded w-full"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700">Description</label>
                            <textarea
                                value={description}
                                placeholder='The issue I am facing is ...'
                                onChange={(e) => setDescription(e.target.value)}
                                className="mt-1 px-4 py-2 border rounded w-full"
                            />
                        </div>
                        <button onClick={handleReportSubmit} className="px-4 py-2 bg-[#78007a] text-white rounded-lg">
                            Submit Report
                        </button>
                        <button onClick={() => setShowReportPopup(false)} className="ml-2 px-4 py-2 bg-gray-400 text-white rounded-lg">
                            Cancel
                        </button>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Dispense;

import React, { useEffect, useState } from 'react';
import { FaExclamation } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const SomethingWW = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { id } = useParams();
    const vmid = useSelector((state) => state.app.machineID)
    const [countdown, setCountdown] = useState(10); // Initial countdown value
  
    useEffect(() => {
      // Extract vending machine ID from the URL
      const redirectURL = `/vendingmachine/${vmid}`;
  
      // Redirect to the URL without query parameters after countdown reaches 0
      const timer = setTimeout(() => {
        navigate(redirectURL);
      }, countdown * 1000); // Convert seconds to milliseconds

      // Reload the page after changing the time   
      const reloadTimer = setTimeout(() => {
        window.location.reload();
      }, countdown * 1000);

      // Decrement countdown every second
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
  
      // Clear timer and interval on component unmount
      return () => {
        clearTimeout(timer);
        clearInterval(countdownInterval);
      };
    }, [navigate, id, location, countdown]);
  
    return (
        <div className=" mt-10 flex items-center justify-center p-6">
        <div className="text-center flex flex-col items-center justify-center p-6">
          <div className="bg-[#78007a] rounded-full p-8 mb-8 flex flex-row justify-center w-fit ">
            <FaExclamation className="text-white w-16 h-16" />
          </div>
          <p className="mb-2 text-gray-700 text-2xl">Something Went Wrong</p>
          <p className="mb-4 text-gray-700">
            We apologize for the inconvenience. You will be redirected to the vending machine page shortly.
          </p>
          <p className="text-gray-700">
            Please contact{' '}
            <a
              href="mailto:thequickcafeofficial@gmail.com"
              className="underline text-pink-800"
            >
              thequickcafeofficial@gmail.com
            </a>{' '}
            for assistance.
          </p>

          <p className='mb-4 text-gray-700'>Redirecting in <b className=' font-extrabold text-xl '>{countdown}</b> seconds...</p>
        </div>
      </div>
    );
};

export default SomethingWW;
import React, { useEffect, useState } from 'react'
import { FaExclamation } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setCurrentPage } from '../actions'

const OfflineMachine = () => {
    const navigate = useNavigate()
    const [seconds, setSeconds] = useState(5)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(setCurrentPage(0))
    }, [])
    useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => {
                setSeconds(seconds - 1)
            }, 1000)
            return () => clearTimeout(timer)
        } else {
            navigate('/')
        }
    }, [seconds, navigate])

    return (
        <div className="mt-10 flex items-center justify-center p-6">
            <div className="text-center flex flex-col items-center justify-center p-6">
                <div className="bg-[#78007a] rounded-full p-8 mb-8 flex flex-row justify-center w-fit">
                    <FaExclamation className="text-white w-16 h-16" />
                </div>
                <p className="mb-2 text-gray-700">
                    Sorry for the inconvenience
                </p>
                <p className="mb-4 text-gray-700">
                    This vending machine is offline. Please try again later.
                </p>
                <p className="text-gray-700 flex flex-col">
                    Please contact{' '}
                    <a
                        href="mailto:thequickcafeofficial@gmail.com"
                        className="underline text-pink-800"
                    >
                        thequickcafeofficial@gmail.com
                    </a>{' '}
                    <a
                        className="underline text-pink-800"
                        href="tel:8421586845"
                    >
                        +91 8421586845{' '}
                    </a>
                    for assistance.
                </p>

                <p className="mt-4 text-gray-700">
                    Redirecting to home in <b className=' font-extrabold text-xl '>{seconds}</b> second
                    {seconds !== 1 ? 's' : ''}...
                </p>
            </div>
        </div>
    )
}

export default OfflineMachine

// src/utils/cookieUtils.js

export function setCookie(name, value, days) {
    const expires = new Date(Date.now() + days * 24 * 60 * 60 * 1000).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}; path=/`;
}

export function getCookie(name) {
    const cookieArr = document.cookie.split('; ');
    for (let cookie of cookieArr) {
        const [key, value] = cookie.split('=');
        if (key === name) return decodeURIComponent(value);
    }
    return null;
}

const MAX_ORDERS = 5;
const ORDER_COOKIE_NAME = 'recentOrders';

export function getOrdersFromCookies() {
    const cookieData = getCookie(ORDER_COOKIE_NAME);
    return cookieData ? JSON.parse(cookieData) : [];
}

export function saveOrdersToCookies(orders) {
    setCookie(ORDER_COOKIE_NAME, JSON.stringify(orders), 7); // Expires in 7 days
}

export function addOrderToCookies(newOrder) {
    let orders = getOrdersFromCookies();

    // Function to check if two orders are identical
    const isSameOrder = (order1, order2) => {
        return order1.total === order2.total &&
            order1.vendingMachineId === order2.vendingMachineId &&
            order1.items.length === order2.items.length &&
            order1.items.every((item, index) => {
                const existingItem = order2.items[index];
                return item.itemName === existingItem.itemName &&
                    item.price === existingItem.price &&
                    item.count === existingItem.count;
            });
    };

    // Remove if the new order is the same as an existing order
    orders = orders.filter(order => !isSameOrder(order, newOrder));

    // Add new order at the beginning
    orders.unshift(newOrder);

    // Limit to the maximum allowed orders
    if (orders.length > MAX_ORDERS) {
        orders.pop();
    }

    saveOrdersToCookies(orders);
}

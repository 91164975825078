import { useDispatch } from 'react-redux'
import { setCart, setCurrentPage, setRedirect, setQueryParams } from '../actions'
import PopupInfoCard from '../Components/PopupInfoCard'
import TotalSummary from './TotalSummary'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { checkoutApi } from '../services/api'
import { addOrderToCookies } from '../Utili/cookieUtils'
import * as DeviceInfo from '../Utili/DeviceInfo'
import Loader from './Loader'

const OrderNowButton = ({ cart, vmid, triggerQuickOrder, onQuickOrderHandled }) => {

    const deviceInfo = DeviceInfo.getDeviceInfo()
    // console.log(`Hello --> ${ deviceInfo.browser.name}`);

    const [responseMessage, setResponseMessage] = useState(null);
    const [errorCardPopup, setErrorCardPopup] = useState(false);
    const [errorHandle, setErrorHandle] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cartArray = Object.values(cart)

    useEffect(() => {
        if (triggerQuickOrder) {
            handleOrder()
            onQuickOrderHandled() // Reset the trigger after handling
        }
    }, [triggerQuickOrder, onQuickOrderHandled])

    const calculateTotal = (cartArray) => {
        return cartArray.reduce((total, item) => total + item.product_price * item.quantity, 0);
    };

    const handleOrder = async () => {
        // Dispatch actions to set cart and current page
        console.log(cart)
        setLoading(true) // start loading animation
        dispatch(setCart(cart))
        
        let ans = await handleButtonClick(); // returs true if no error else false
        console.log(`fetched Status __>${ans}`);

        setLoading(false) // stop loading animation

        if(ans){
            dispatch(setCurrentPage(2))
        }else{
            // Do nothing error will be displayed automatically from the API call section
        }
    }
    console.log("cartArray: ",cartArray)
    
    const handleButtonClick = async () => {
        // show ui loader 
        try {
            const items = cartArray.map((item) => ({
                invid: item.id,
                count: item.quantity,
                itemName: item.product_name,
                price: item.product_price,
                subtotal: item.product_price * item.quantity,
            }));

            const requestData = {
                cart_data: {
                    items,
                    total: calculateTotal(cartArray),
                    uniqueitemcount: cartArray.length,
                },
                vending_machine: {
                    id: cartArray[0].vendingmachines_id,
                },
                payment_info: {
                    pg: 'PHONEPE',//'TQCTESTPG',
                    mobile: '',
                    ppepg: {
                        payment_instrument_type: 'OTHERINSTRUMENTS',
                    },
                },
            };
            // console.log("REQUEST DDATA -> ");
            // console.log(requestData);
            
            

            // Add order to cookies in LRU format after successful order
            console.log("Adding the Cookie");
            
            addOrderToCookies({
                id: Date.now(), // or response.data.orderId if available
                items,
                total: requestData.cart_data.total,
                requestData,
                timestamp: new Date().toISOString(),
                vendingMachineId: vmid, // Include the VM ID
            });

            const response = await axios.post(
                checkoutApi,
                requestData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-DEVICEBROWSER': deviceInfo.browser.name || 'MOB_CHROME', //deviceInfo.browser, 'MOB_CHROME'
                        'X-DEVICEOS':  deviceInfo.browser.os || 'ANDROID',//deviceInfo.os,'ANDROID'
                    },
                }
            );
            //'MOB_CHROME',
            //'ANDROID',
            setResponseMessage(response.data);
            console.log(response.data);
            const redirectUrl = response.data.Data.PayRsponse.data.instrumentResponse.redirectInfo.url ;
            const queryURL = getQuery(response.data.Data.Redirect.url)
            console.log(`Query URL --> ${queryURL}`);

            //adding the QUERY URL  TO IT.
            addOrderToCookies({
                id: Date.now(), // or response.data.orderId if available
                items,
                total: requestData.cart_data.total,
                requestData,
                queryURL,
                timestamp: new Date().toISOString(),
                vendingMachineId: vmid, // Include the VM ID
            });

            // hide ui loader 
            // show auto close popup for 1 sec and hide
            console.log("STrated Redicteig"); 
            dispatch(setRedirect(redirectUrl))
            dispatch(setQueryParams(queryURL))

            return true;

        } catch (error) {
            console.error('Error processing payment:', error);
            openErrorPopup(error)
            setErrorHandle(error);
            return false;
        }
    };

    const getQuery = (url) => {
        const params = "";
        const urlParts = url.split('?');
        if (urlParts.length > 1) {
            const queryString = urlParts[1];
            return queryString
        }
        return params;
    };

    const openErrorPopup = (err) => {
        setErrorCardPopup(true)
        setErrorHandle(err)
        console.log(err);
    }

    const closeErrorPopup = () => {
        setErrorCardPopup(false)
    }

    const isCartEmpty = cartArray.length === 0;

    return (
        <div className="flex flex-col justify-center items-center fixed bottom-2 w-full">
            <TotalSummary cart={cart} />
            <button
                className={`w-[20rem] rounded-lg ${isCartEmpty ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-[#78007a] text-white'} p-2 text-lg mt-4`}
                onClick={handleOrder}
                disabled={isCartEmpty}
            >
                Order Now
            </button>
            
            {/* Error Pop up card */}
            {errorCardPopup && (
                <PopupInfoCard title={errorHandle.response.data.Status || errorHandle.response.request.status} 
                                message={errorHandle.response.data.Error || errorHandle.response.data.message} closePopup={closeErrorPopup} />
            )}

            { loading && (<Loader />) }
        </div>
    )
}

export default OrderNowButton
